import React from "react"
import AboutLayout from "../../components/layouts/aboutLayout"

const NewsPage = props => (
  <AboutLayout pageTitle="News">
    <p>News stories will go here...</p>
  </AboutLayout>
)

export default NewsPage
